<script>
  /**
   * A layout component that arranges `BasePromoModule` components into a grid.
   */
  export default {
    props: {
      /** The grid's layout shape. */
      shape: {
        type: String,
        default: 'landscape',
        validator: (value) => ['square', 'landscape', 'portrait', 'quadrant'].includes(value),
      },
    },
  }
</script>

<template>
  <!-- for purgecss: shape--square shape--landscape shape--portrait shape--quadrant -->
  <div :class="`grid shape--${shape}`">
    <slot />
  </div>
</template>

<style lang="postcss" scoped>
  .shape {
    &--square {
      @apply grid-cols-1;
    }

    &--landscape {
      @apply grid-cols-2;
    }

    &--portrait {
      @apply grid-rows-2;
    }

    &--quadrant {
      @apply grid-rows-2 grid-flow-col;
    }
  }
</style>
